import React, { useState, Fragment, useEffect } from "react";
import { MapContainer, TileLayer, useMap, ZoomControl } from "react-leaflet";
import ParkingLots from "./ParkingLots";
import Control from 'react-leaflet-custom-control'
import { HandicapSpaces, ElecSpaces } from "./ParkingSpaces";
import { createHandicapClusterIcon, createElectricClusterIcon } from "../lib/CustomIcons";
import MarkerClusterGroup from "react-leaflet-cluster";
import Media from 'react-media';
import getParam from '../lib/MapParam';
import { Dropdown } from "./Overlay";


enum ScreenSize {
  mobile,
  tablet,
  laptop,
  desktop,
  tv
}

function MapListener() {
  const map = useMap()
  //console.log("wait ffor Invalidate");
  useEffect(() => {
    setTimeout(() => {
      //console.log("Invalidate");
      map.invalidateSize();
    }, 250);
  }, [map])
  return null
}

function buildMap(screenSize) {
  const param = getParam(screenSize);

  return <MapContainer
    id="mapId"
    zoom={param.zoomFromUrl}
    center={param.centerFromUrl}
    maxBounds={param.boundsFromUrl}
    maxBoundsViscosity={1}
    maxZoom={param.maxZoom}
    minZoom={param.minZoom}
    attributionControl={false}
    zoomControl={false}
  >

    <MapListener />
    <TileLayer
      url="https://api.mapbox.com/styles/v1/thomassensade/claupmiid004a15mwyixh7hqy/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidGhvbWFzc2Vuc2FkZSIsImEiOiJjbGF0Y3RzdjUwNmk0M3ZzNXl3cWtuOXB0In0.ekRPw_Mdgz5FcxvHFs9vbg"
      maxZoom={22} />

    <Control position='topleft'>
      <ZoomControl />
      <Dropdown/>
   </Control>

    <MarkerClusterGroup
      chunkedLoading
      iconCreateFunction={createHandicapClusterIcon}
      maxClusterRadius={30}
      showCoverageOnHover={false}
      disableClusteringAtZoom={19}
      spiderfyOnMaxZoom={false}
    >
      <HandicapSpaces />
    </MarkerClusterGroup>
    <MarkerClusterGroup
      chunkedLoading
      iconCreateFunction={createElectricClusterIcon}
      maxClusterRadius={30}
      showCoverageOnHover={false}
      disableClusteringAtZoom={19}
      spiderfyOnMaxZoom={false}
    >
      <ElecSpaces />
    </MarkerClusterGroup>

    <ParkingLots />
  </MapContainer>
}

function Map(props) {
  const [map, setMap] = useState(null);
  return (
    <Media queries={{
      mobile: "(max-width: 480px)",
      tablet: "(min-width: 481px) and (max-width: 768px)",
      laptop: "(min-width: 769px) and (max-width: 1024px)",
      desktop: "(min-width: 1025px) and (max-width: 1200px)",
      tv: "(min-width: 1201px)"
    }}>
      {matches => (
        <Fragment>
          {matches.mobile && buildMap(ScreenSize.mobile)}
          {matches.tablet && buildMap(ScreenSize.tablet)}
          {matches.laptop && buildMap(ScreenSize.laptop)}
          {matches.desktop && buildMap(ScreenSize.desktop)}
          {matches.tv && buildMap(ScreenSize.tv)}
        </Fragment>
      )}
    </Media>
  )
};

export default Map;
