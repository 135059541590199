import React, { useEffect } from "react";
import axios from "axios";


export function GetData(props) {
    const [data, setData] = React.useState();

      useEffect(() => {
        const getData = async () => {
          const response = await axios.get(props);
          setData(response.data);
        };
        getData();
      }, []); 
  
      if (data) {  
        return data;
      } else {
        return null;
      }
    };
