const BaseUrl = process.env.REACT_APP_BASE_URL;
const OrgId = process.env.REACT_APP_ORG_ID;

export function UrlToQuery(props){
  var urlToQuery = ''



    switch(props) {
        case 'parkingLots':
          urlToQuery = (BaseUrl + OrgId + "/parkinglots");
          break;
      
        case 'parkingStatus':
          urlToQuery = (BaseUrl + OrgId + "/parkinglots/status");
          break;

        case 'handicapSpot':
          urlToQuery = (BaseUrl + OrgId + "/parkingspaces?type=handicap");
          break;

        case 'elSpot':
          urlToQuery = (BaseUrl + OrgId + "/parkingspaces?type=el car");
          break;

        default:
            urlToQuery = (BaseUrl + OrgId + "/parkinglots");
            break;
      }

    return urlToQuery;
}