import { GetData } from "../lib/DataRetrieval";
import { ElecIcon, HandicapIcon } from "../lib/CustomIcons";
import { UrlToQuery } from "../lib/Endpoints";
import { Marker } from "react-leaflet";

export function HandicapSpaces(props) {
    const MarkerType = { 
        data: GetData(UrlToQuery("handicapSpot")),
        icon: HandicapIcon
    }
    return (ParkingMarker(MarkerType));
}

export function ElecSpaces(props) {
    const MarkerType = { 
        data: GetData(UrlToQuery("elSpot")),
        icon: ElecIcon
    }
    return (ParkingMarker(MarkerType));

}
function ParkingMarker(props){
    const data = props.data;
    const icon = props.icon;
    
    if (data != null) {
        
        return    data.map((address, index) => (
                    <Marker
                        key={index}
                        position={[address.latitude, address.longitude]}
                        icon={icon}
                    ></Marker>
                    ))
    }
    
    else {
        return;
    }
}