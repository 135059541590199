import { CDropdown, CDropdownHeader, CDropdownToggle, CDropdownMenu, CDropdownDivider } from '@coreui/react';
import elecIconPic from '../img/boltInMarkerBackground.svg';
import handicapIconPic from '../img/handicapInMarker.svg';
import menuBarsPic from '../img/menu.svg'
export function Dropdown()  {

  return (
    
    <CDropdown variant="btn-group" direction="dropend">
      <CDropdownToggle><img className='dropdown-button-logo' src={menuBarsPic}/></CDropdownToggle>
      <CDropdownMenu>
        <CDropdownHeader>Availability</CDropdownHeader>
        <p className="m-1"><div className='box green'></div>High</p>
        <p className="m-1"><div className='box yellow'></div>Medium</p>
        <p className="m-1"><div className='box red'></div>Low</p>
        <p className="m-1"><div className='box blue'></div>No data</p>
        <CDropdownDivider />
        <p className="m-1" ><img src={handicapIconPic} alt="" width="25" height="25" />  Accessible parking</p>
        <p className="m-1" white-space="nowrap"><img src={elecIconPic} alt="" width="25" height="25" />  EV charging station</p>
      </CDropdownMenu>      
    </CDropdown>
  );

}
