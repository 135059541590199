import L from "leaflet";

enum ScreenSize {
    mobile,
    tablet,
    laptop,
    desktop,
    tv
}

export default function getParam(screenSize) {

    let parameters: any = {};
    parameters.centerFromUrl = [0, 0];
    
    if (window.location.href.indexOf("lyngby") !== -1) {
        parameters.centerFromUrl = [55.78506223820306, 12.522050329935473];
        var southWest = L.latLng(55.774058, 12.490005);
        var northEast = L.latLng(55.800347, 12.543881);

        if (screenSize === ScreenSize.mobile) { parameters.zoomFromUrl = 15; }
        else if (screenSize === ScreenSize.tablet) { parameters.zoomFromUrl = 15.5; }
        else if (screenSize === ScreenSize.laptop) { parameters.zoomFromUrl = 16; }
        else if (screenSize === ScreenSize.desktop) { parameters.zoomFromUrl = 16; }
        else if (screenSize === ScreenSize.tv) { parameters.zoomFromUrl = 16; }
        else { parameters.zoomFromUrl = 16; }
        parameters.maxZoom = 22;
        parameters.minZoom = 15;
        parameters.boundsFromUrl = L.latLngBounds(southWest, northEast);
    }
    else if (window.location.href.indexOf("risoe") !== -1) {
        parameters.centerFromUrl = [55.691933,12.0966541];
        var southWest = L.latLng(55.686016,12.0714931);
        var northEast = L.latLng(55.700125, 12.116562);
        if (screenSize === ScreenSize.mobile) { parameters.zoomFromUrl = 15; }
        else if (screenSize === ScreenSize.tablet) { parameters.zoomFromUrl = 15; }
        else if (screenSize === ScreenSize.laptop) { parameters.zoomFromUrl = 16; }
        else if (screenSize === ScreenSize.desktop) { parameters.zoomFromUrl = 16; }
        else if (screenSize === ScreenSize.tv) { parameters.zoomFromUrl = 16; }
        else { parameters.zoomFromUrl = 16; }
        parameters.maxZoom = 22;
        parameters.minZoom = 15;
        parameters.boundsFromUrl = L.latLngBounds(southWest, northEast);
    }
    else if (window.location.href.indexOf("ballerup") !== -1) {
        parameters.centerFromUrl = [55.731135, 12.396466];
        var southWest = L.latLng(55.724365, 12.3738741); 
        var northEast = L.latLng(55.738457, 12.4206241);
        if (screenSize === ScreenSize.mobile) { parameters.zoomFromUrl = 16; }
        else if (screenSize === ScreenSize.tablet) { parameters.zoomFromUrl = 17; }
        else if (screenSize === ScreenSize.laptop) { parameters.zoomFromUrl = 17; }
        else if (screenSize === ScreenSize.desktop) { parameters.zoomFromUrl = 17; }
        else if (screenSize === ScreenSize.tv) { parameters.zoomFromUrl = 17; }
        else { parameters.zoomFromUrl = 17; }
        parameters.maxZoom = 22;
        parameters.minZoom = 16;
        parameters.boundsFromUrl = L.latLngBounds(southWest, northEast);
    }

    return (parameters)
}
