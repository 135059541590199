import L, { Icon } from "leaflet";
import elecIconPic from '../img/boltInMarkerBackground.svg';
import handicapIconPic from '../img/handicapInMarker.svg';

export const HandicapIcon = new Icon({
    iconUrl: handicapIconPic,

    iconSize:     [25, 25], // size of the icon
    iconAnchor:   [12.5, 12.5], // point of the icon which will correspond to marker's location
    popupAnchor:  [0, 0] // point from which the popup should open relative to the iconAnchor
});

export const ElecIcon = new Icon({
    iconUrl: elecIconPic,

    iconSize:     [25, 25], // size of the icon
    iconAnchor:   [12.5, 12.5], // point of the icon which will correspond to marker's location
});


const handicapClusterIcon = new L.Icon({
    iconUrl: require('../img/handicapInMarker.svg').default,
    iconSize: new L.Point(25, 25),
  })
  
  const electricClusterIcon = new L.Icon({
    iconUrl: require('../img/boltInMarkerBackground.svg').default,
    iconSize: new L.Point(25, 25),
  })
  
export  const createHandicapClusterIcon = function (cluster) {
    return handicapClusterIcon;
  };
  
export  const createElectricClusterIcon = function (cluster) {
    return electricClusterIcon;
  };
