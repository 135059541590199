import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { routes as appRoutes } from "./Routes";
import Map from './components/Map';
import './lib/App.css';


function App() {

  return (
        <Router>
          <Routes>
            {appRoutes.map((route) => (
              <Route
                key={route.key}
                path={route.path}
              />
            ))}
          </Routes>
        
          <Map /> 

        </Router>
    );
}

export default App;