// interface
interface Route {
    key: string,
    title: string,
    path: string,
    enabled: boolean
}

export const routes: Array<Route> = [
    {
        key: 'lyngby-route',
        title: 'Lyngby',
        path: '/lyngby',
        enabled: true
    },
    {
        key: 'ballerup-route',
        title: 'Ballerup',
        path: '/ballerup',
        enabled: true
    },
    {
        key: 'risoe-route',
        title: 'Risø',
        path: '/risoe',
        enabled: true
    }
]