export function mapColorScaleOccupancy(props) {
    var status = props;
        console.log(status);
        if (( 1 - status) < 0.6) {
            return ('rgb(31, 208, 130)');
        }
        else if (( 1 - status) < 0.9) {
            return ('rgb(246, 208, 77)');
        }
        else {
            return ('rgb(252, 118, 52)');
        }
    }

export function mapColorNoData() {
    return ('rgb(47, 62, 234)' );
}