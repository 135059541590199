import L from "leaflet";
import { useMap } from "react-leaflet";
import { GetData } from "../lib/DataRetrieval";
import { UrlToQuery } from "../lib/Endpoints";
import { mapColorScaleOccupancy, mapColorNoData } from "../lib/ColorPalette";
import {useState, useEffect} from 'react'

const drawnItems = new L.GeoJSON();

export default function ParkingLots(props) {
    const init = new Date()
    const [date, setDate] = useState(init)

    const data = GetData(UrlToQuery("parkingLots"));
    const status = GetData(UrlToQuery("parkingStatus"));
    const map = useMap();

    const tick = () => {
        setDate(new Date())
      }
    
      useEffect(() => {
        const timerID = setInterval(() => tick(), 120000)
        return () => {
          clearInterval(timerID)
        }
      }, [])

    if (data != null) {

        map.removeLayer(drawnItems);
        drawnItems.clearLayers();
        map.addLayer(drawnItems);

        for (let i = 0; i < data.length; i++) {
            let obj = data[i];

            if (!obj.geo) {
                console.log(obj.name + " has no associated geometry")
                continue;
            }

            else if (obj.hasLiveData && status != null) { 
                for (let i = 0; i < status.length; i++) {
                    let statObj = status[i];
        
                    if (obj.id === statObj.id) {
                           var polygon = L.polygon(obj.geo, {color: mapColorScaleOccupancy(statObj.status), 
                            opacity: 1,
                            fillOpacity: 0.5,
                            weight:5});
                    }
                }
            }
            else {
                var polygon = L.polygon(obj.geo, {color: mapColorNoData(), opacity:0.7});
            }

            drawnItems.addLayer(polygon);
        }
        
        return ;
    }

    else {
        return null;
    }
}

